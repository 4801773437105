import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "@app/index.scss";
import * as serviceWorker from "@app/serviceWorker";
import { UnsupportedBrowserPage } from "@root/UnsupportedBrowserPage";

const { detect } = require("detect-browser");

const SupportedBrowser = () => {
  const App = React.lazy(() => import("@root/App"));
  const RouteLoader = React.lazy(() =>
    import(`@common_components/RouteLoader`).then(module => ({
      default: module["RouteLoader"]
    }))
  );
  return (
    <>
      <Suspense fallback={<div />}>
        <Suspense fallback={<RouteLoader />}>
          <App />
        </Suspense>
      </Suspense>
    </>
  );
};
const UnSupportedBrowser = () => {
  return (
    <>
      <UnsupportedBrowserPage />
    </>
  );
};

const ApplicationLoader = () => {
  const browser = detect();
  switch (browser && browser.name) {
    case "ie":
      return <UnSupportedBrowser />;
    default:
      console.log("Supported", browser);
  }
  return <SupportedBrowser />;
};

ReactDOM.render(<ApplicationLoader />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
