module.exports = {
  //themed images start
  navbar_expanded_logo_icon: require("./traceologyNavbarIconExpanded.png"),
  site_logo_large: require("./traceologyLargeIcon.png"),
  site_logo: require("./traceologyNavbarIcon.png"),
  //themed images end
  profile_pic: require("../../common/profile.png"),
  logo: require("../../common/logo.svg"),
  graph: require("../../graph/graph.png"),
  kingfisher: require("../../common/kingfisher.png"),
  nav_dashboard: require("../../common/navigation/dashboard@2x.png"),
  nav_validateAnalytics: require("../../common/navigation/ic_analytics_white@2x.png"),
  nav_engagement: require("../../common/navigation/engagementView@2x.png"),
  nav_gear: require("../../common/navigation/gear@2x.png"),
  nav_var_view: require("../../common/navigation/verificationView@2x.png"),
  back_arrow: require("../../incidentReporting/back-arrow.png"),
  share_icon: require("./share_icon.png"),
  export_icon: require("./export_icon.png"),
  forward_arrow: require("../../incidentReporting/forward-arrow.png"),
  navigation_icon: require("./navigation.png"),
  thumbs_down_in_circle: require("../../incidentReporting/thumbsDownInCircle.png"),
  thumbs_up_in_circle: require("../../incidentReporting/thumbsUpInCircle.png"),
  interm: require("../../incidentReporting/interm.png"),
  noImageShared: require("../../incidentReporting/noImageShared.png"),
  prevPageArrow: require("../../common/prevPageArrow.png"),
  pagePlaceholder: require("../../common/pagePlaceholder.png"),
  antiCounterfeitPlaceholder: require("../../common/anti_counterfeit.png"),
  incidentViewPlaceholder: require("../../common/Incident_View.png"),
  unsupportedBrowser: require("../../common/unsupported_browser.jpg"),
  red_tick: require("../../common/red_tick.png"),
  green_tick: require("../../common/green_tick.png"),
  dashboard: {
    lock_icon: require("../../dashboard/widget_lock.png"),
    header_icon: require("../../dashboard/header/Group.png"),
    summary: {
      noVerification: require("../../dashboard/summary/noVerification.png"),
      percentageCounterfeits: require("../../dashboard/summary/percentageCounterfeits.png"),
      numberOfReportedIncidents: require("../../dashboard/summary/numberOfReportedIncidents.png"),
      numberOfSuspects: require("../../dashboard/summary/ic_noof_suspects@2x.png"),
      upArrowGreen: require("../../dashboard/summary/upArrowGreen.png"),
      upArrowRed: require("../../dashboard/summary/upArrowRed.png"),
      downArrowGreen: require("../../dashboard/summary/downArrowGreen.png"),
      downArrowRed: require("../../dashboard/summary/downArrowRed.png"),
      //themed images start
      numberOfActiveUsers: require("./activeUsersIcon.png")
      //themed images end
    },
    filters: {
      //themed images start
      brand_filter: require("./brandFilterIcon.png"),
      region_filter: require("./stateFilterIcon.png"),
      user_filter: require("./consumerFilterIcon.png"),
      date_filter: require("./calendarFilterIcon.png"),
      agency_filter: require("./consumerFilterIcon.png"),
      label_filter: require("./label_filter@2x.png")
      //themed images end
    },
    grid: {
      right_menu: require("../../dashboard/grid/right_menu.png"),
      sort_up: require("../../dashboard/grid/sort_up.png"),
      sort_down: require("../../dashboard/grid/sort_down.png")
    }
  },
  mondelez: {
    appstore_icon: require("../../mondelez/appstore_icon.png"),
    chkfake_logo: require("../../mondelez/chkfake_logo.png"),
    googleplay_icon: require("../../mondelez/googleplay_icon.png"),
    mondelez_logo: require("../../mondelez/mondelez_logo.png"),
    mondelez_video_image: require("../../mondelez/mondelez_video_image.png")
  },
  notification_in_active: require("../../common/menu/notificationInactive.png"),
  profile_in_active: require("../../common/menu/profileInactive.png"),
  alert_icon: require("../../common/alerts/Alert.png"),
  clear_icon: require("../../common/alerts/Clear.png"),
  counterfeit_Alert_icon: require("../../common/alerts/Counterfeit_Alert.png"),
  code_scan_icon: require("../../common/alerts/code_scan_icon.png"),
  clear_small: require("../../common/alerts/clearSmall.png"),

  ic_diversion_white: require("../../diversionAnalytics/ic_diversion_white.svg"),
  diversionAnalytics: {
    lock_icon: require("../../dashboard/widget_lock.png"),
    header_icon: require("../../diversionAnalytics/ic_diversion_black.svg"),
    ic_diversionalert: require("../../diversionAnalytics/ic_diversionalert.svg"),
    up_triangle: require("../../diversionAnalytics/up-triangle.svg"),
    down_triangle: require("../../diversionAnalytics/down-triangle.svg"),
    summary: {
      upArrowGreen: require("../../dashboard/summary/upArrowGreen.png"),
      upArrowRed: require("../../dashboard/summary/upArrowRed.png"),
      downArrowGreen: require("../../dashboard/summary/downArrowGreen.png"),
      downArrowRed: require("../../dashboard/summary/downArrowRed.png"),
      ic_numberofdiversions: require("../../diversionAnalytics/ic_numberofdiversions.svg"),
      ic_numberofscans: require("../../diversionAnalytics/ic_numberofscans.svg"),
      ic_numberofusers: require("../../diversionAnalytics/ic_numberofusers.svg"),
      ic_diversions: require("../../diversionAnalytics/ic_diversions.svg")
    },
    filters: {
      brand_filter: require("./brandFilterIcon.png"),
      region_filter: require("./stateFilterIcon.png"),
      user_filter: require("./consumerFilterIcon.png"),
      date_filter: require("./calendarFilterIcon.png"),
      agency_filter: require("./consumerFilterIcon.png")
    },
    grid: {
      right_menu: require("../../dashboard/grid/right_menu.png"),
      sort_up: require("../../dashboard/grid/sort_up.png"),
      sort_down: require("../../dashboard/grid/sort_down.png")
    }
  },
  ic_close: require("../../diversionAnalytics/ic_close.svg"),
  ic_arrowright_white: require("../../diversionAnalytics/ic_arrowright_white.svg"),
  ic_arrowleft_white: require("../../diversionAnalytics/ic_arrowleft_white.png"),
  ic_arrowright_black: require("../../diversionAnalytics/ic_arrowright_black.svg"),
  ic_arrowdown_black: require("../../diversionAnalytics/ic_arrowdown_black.svg"),
  next_page: require("../../diversionAnalytics/next_page.svg"),
  prev_page: require("../../diversionAnalytics/previous_page.svg"),
  btn_export: require("../../diversionAnalytics/btn_export.svg"),
  MC_Dowells_180ML_Front: require("../../diversionAnalytics/MC_Dowells_180ML_Front.png"),
  backwardDisablePagination: require("../../diversionAnalytics/backwardDisablePagination.svg"),
  forwardActivePagination: require("../../diversionAnalytics/forwardActivePagination.svg"),
  ic_arrowup_square_bsec: require("../../common/ic_arrowup_square_bsec.png"),
  ic_arrowdown_square_black: require("../../common/ic_arrowdown_square_black.svg"),
  ic_red_green_marker: require("../../common/ic_red_green_marker.png"),
  ic_fullscreen_exit: require("../../common/ic_fullscreen_exit.png"),
  ic_fullscreen: require("../../common/ic_fullscreem.png"),
  markerGreen: require("../../common/ic_storemarker_green.png"),
  markerRed: require("../../common/ic_storemarker_red.png"),
  ic_clustor: require("../../common/ic_clustor.png"),
  ic_epc_search: require("../../diversionAnalytics/ic_epc_search.svg"),
  ic_search_black: require("../../diversionAnalytics/ic_search_black.svg")
};
