/**  Keep this file to use as less imports or parts of the main code as possible. 
 This is not supposed to use any feature which is not supported by IE 11
*/
import React from "react";
import { unsupportedBrowser } from "@images";

export const UnsupportedBrowserPage = () => {
  const wrapperStyles = { display: "flex", justifyContent: "center" };
  const imageStyles = { height: "100vh" };
  return (
    <div style={wrapperStyles}>
      <img src={unsupportedBrowser} alt="" style={imageStyles} />
    </div>
  );
};
